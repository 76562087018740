import request from '@/utils/request'

// 获取列表
export function getListRequest(params) {
  return request({
    url: '/admin/wx_userpushcode/getList',
    method: 'get',
    params
  })
}

// 下拉框
export function getSelectRequest(params) {
  return request({
    url: '/admin/wx_userpushcode/getSelectgrade',
    method: 'get',
    params
  })
}

// 删除
export function delRequest(params) {
  return request({
    url: '/admin/wx_userpushcode/del',
    method: 'get',
    params
  })
}

// 获取详情信息
export function getdatilRequest(params) {
  return request({
    url: '/admin/wx_userpushcode/getdatil',
    method: 'get',
    params
  })
}

// 修改信息
export function editRequest(data) {
  return request({
    url: '/admin/wx_userpushcode/edit',
    method: 'post',
    data
  })
}

// 添加信息
export function addRequest(data) {
  return request({
    url: '/admin/wx_userpushcode/add',
    method: 'post',
    data
  })
}